/*
 * AirShareBase.css
*/
.dummyclass {
    display:none;
}

/* 各セクションのタイトル領域 */
.confirmSectionTitle {
	display:block;
	text-align:center;
	font-size:16px;
	margin-bottom:20px;
	border-bottom:thin solid #e6e3e3;
	font-weight:700;
	margin-top: 5px;
}
/* 各ラベル表現 */
.lblbase_noenter {
	display:block;
	margin:0;
}
@media (min-width:650px) {
    .lblbase_noenter {
    	display:inline-block;
    }
    .confirmSectionTitle {
		display:inline-block;
		max-width:1000px;
		width:100%;
		height:36px;
		float:left;
		text-align:left;
    }
}

/* ボタン系 */
.uploadAdjustTextBox {
    padding-top:0px;
    top:0px;
}

@media (min-width:650px) {
    
    .uploadAdjustTextBox {
        padding-top:0px;
        top:-10px;
    }
}

/* テーブル表現 */
.tableBox {
	display:inline-block;
	width:100%;
	margin:auto
}

.tableItemHalf {
	display:inline-block;
	width:100%;
	height:30px;
	margin:auto
}

.tableItemTextBox {
    position:float;
    height:60px!important;
}
.tableItemTextLittleBox {
    position:float;
    height:36px!important;
}
.tableItemTextItem {
    position:float;
    width:100%;
    bottom:0px;
}
.tableItemTextMargin {
	display:flex;
	margin-left: 2px;
}
.tableItemTextLittleMargin {
    margin-left:10px;
}


.hr_1 {
    margin: 0 20px;
    border: 0;
    border-top: 1px solid #eee;
}

@media (min-width:650px) {
	.tableBox {
    	margin-left:20px;
		width:calc(100% - 20px);
	}
    .tableItemHalf {
    	width:45%;
		height:30px;
		margin-right: 5px;
    }
    .tableItemTextBox {
        position:relative;
    }
    .tableItemTextLittleBox {
        position:relative;
    }
    .tableItemTextItem {
        position:absolute;
        bottom:0px;
    }
    .tableItemTextLittleMargin {
        top:-22px!important;
    }
}

/* ゴミ箱 */
.delete {
	width:30px;
	height:30px;
	cursor:pointer
}

/*
 * RegistrationBase.css
*/

/* 各入力情報 */

.formControl {
	display:inline-block;
	max-width:100%;
	width:100%;
	height:36px;
	padding:.375rem .75rem;
	font-size:1rem;
	line-height:1.5;
	color:#CCC;
	background-color:#F5F5F5;
	background-clip:padding-box;
	border:1px solid #E4E4E4;
	border-radius:3px;
	transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.confirmControlHalf {
	display:inline-block;
	max-width:100%;
	width:100%;
	height:36px;
}

@media (min-width:650px) {
	.formControl {
		display:inline-block;
    	/*max-width:200px;*/
    	width:83%;
	}
	.confirmControlHalf {
		display:inline-block;
    	/*max-width:200px;*/
    	width:40%;
     	margin-top:10px;
	}
}

/* 各エラー表現 */
.customErrorShow {
    margin-top:10px;
	margin-left:5px;
	color:red;
	font-size:12px
}
.customErrorLeft {
	display:block;
	margin-left:5px;
	color:red;
	font-size:12px
}

@media (min-width:650px) {
    .customErrorLeft {
    	margin-left:20px;
    }
}

/**
 * RegistrationPilot.css
*/

.uploadCover {
	position:relative;
	display: inline !important;
}
.displayNoneClass{
	display: none;
}
.marginLeft{
	margin-left: 1%;
}

/* TODO:テキストボックス再作成後クラスに置き換え */
#x_skill_number,
#x_examination_number {
	width: 232px;
}

.formControlHalfPlus:disabled,
.formControlThrid:disabled {
	background-color: #e4e4e4 !important;
}
