/*
* RegistrationBase.css
*/

/* 各入力情報 */

.formControlTable {
	display:inline-block;
	width:66%;
	height:36px;
	padding:.375rem .75rem;
	font-size:1rem;
	line-height:1.5;
	color:#CCC;
	background-color:#F5F5F5;
	background-clip:padding-box;
	border:1px solid #E4E4E4;
	border-radius:3px;
	transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
@media (min-width:650px) {
	.formControlTable {
		display:inline-block;
    	/*max-width:200px;*/
	}
}

/* テーブル表現 */
.tableItemHalf2 {
	display:inline-block;
	width:88%;
	height:30px;
	margin:auto
}

.tableItemLittle {
	display:inline-block;
	margin-left: 0px;
	margin-bottom:10px;
	vertical-align: sub;
}

@media (min-width:650px) {
    .tableItemHalf2 {
    	width:35%;
    	height:30px;
    }
    .tableItemLittle {
        padding-top:  10px;
		margin-bottom: 0px;
		width: 15%;
    }
}


/*
* RegistrationOwner.css
*/

.displayDiv {
    display: none;
}

.hideDiv {
    display: block;
    margin-top: 14px;
}

/* TODO:テキストボックス再作成後クラスに置き換え */
#symbol {
    width: 40%;
}
#classification, #parking_area{
    width: auto;
}


@media (min-width: 650px) {
    .hideDiv {
        display: none;
        margin-top: 14px;
    }

    .displayDiv {
        display: inline-block;
        width: 35%;
    }

}

