
.footerTop{
	width:100%;
	max-width: 800px;
	margin:0px auto;
	padding: 40px 0;
	color: #515151;
	font-size: 10px;
}
.footerContentBox{
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* margin: 40px 0; */
}
.footerSiteMap{
	justify-content: flex-start;
}
.footerSiteMapBox{
	display: flex;
	justify-content: space-around;
	width: 100%;
}
.footerSiteMapContent{
	margin:5px;
}
.end_middle_text{
	color: #000;
	font-size: 10px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
}
.fotterSNSBox{
	display: flex;
}
.footerSNS{
	width:30px;
	height: 30px;
	margin:0 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.footerImg{
	width:auto;
	height: 30px;
	margin: 0px !important;
}

@media (max-width:880px) {
	.footerTop{
		padding: 40px 5%;
	}
}

@media (max-width:600px) {
	.footerContentBox{
		flex-wrap: wrap;
		justify-content: center;
		flex-direction: column;
	}
	.footerSiteMapBox{
		flex-direction: column;
	}
	.footerSiteMapContent{
		margin:15px 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.end_middle_text{
		align-items: center;
		justify-content: center;
	}
	.fotterSNSBox{
		margin-top: 30px;
	}

	.footerSNS{
		width:35px;
		height: 35px;
	}
	.footerImg{
		height: 35px;
	}
}
