/*
 * AirShareBase.css
*/
.formBtnBoxFive {
	display: table;
    table-layout: fixed;
    text-align: center;
	width:100%;
	margin:30px auto 50px;
}
.formBtnBoxFiveGroup1 {
    display: block;
    width:100%;
}
.formBtnBoxFiveGroup2 {
    display: block;
    width:100%;
}
.formBtnBoxFiveGroupBox {
    display: block;
    width:100%;
}
@media (min-width:650px) {
	
    .formBtnBoxFive {
        max-width:1020px;
    	width:95%;
    }
    .formBtnBoxFiveGroup1 {
        display: table-cell;
        vertical-align: middle;
        width:50%;
        min-width:385px;
        padding-right:5%;
    }
    .formBtnBoxFiveGroup2 {
        display: table-cell;
        vertical-align: middle;
        width:35%;
        min-width: 550px;
    }
    .formBtnBoxFiveGroupBox {
    	display: table;
        table-layout: fixed;
        text-align: center;
    	width:100%;
    	margin:30px auto 50px;
    }

	.formBtnBoxFiveGroupBoxFlex {
		display: flex !important;
		flex-wrap: wrap !important;
	}
}

@media (max-width:900px) {
    .formBtnBoxFiveGroup1 {
        display: block;
        vertical-align: middle;
        width:100%;
    }
    .formBtnBoxFiveGroup2 {
        display: block;
        vertical-align: middle;
        width:100%;
    }
    .formBtnBoxFiveGroupBox {
    	display: table;
        table-layout: fixed;
        text-align: center;
    	width:90%;
    	margin:0px auto 10px;
    }
}

/* 必須アイコン */
.required_mobile {
	display:inline-block;
	background-color:#DD4B40;
	color:#fff;
	padding:2px 5px;
	font-size:10px;
	font-weight:700;
	position:relative;
	top:3px;
	float:right;
	height: 16px;
	width: 35px;
	line-height: 16px;
	text-align: center;
}

@media (min-width:650px) {
	.required_mobile {
		display:none
	}
}
.required_specialFix {
	position:relative;
	top:-6px
}

/* ボタン系 */

.formBtnItemSmall {
    display: block;
}
@media (min-width:650px) {
    .formBtnItemSmall {
        display: table-cell;
        vertical-align: middle;
    }
}

.temp_body_box {
	margin-top: 180px;
	font-size: 28px;
}
/*仮登録時のユーザ情報に出す文字*/
@media (min-width:601px) {
    .temp_body_box {
        margin-top: 180px;
        font-size: 36px;
    }
    .temp_button_box {
        margin-top: 100px;
    }
}

/**
 * RegistrationBase.css
*/

/* 各エラー表現 */
.customErrorUser {
	display:none;
	margin-left:5px;
	color:red;
	font-size:12px
}

@media (min-width:650px) {
    .customErrorUser {
    	margin-left:20%;
    }
}

/**
 * RegistrationUser.css
*/
/* ユーザ登録画面固有情報 */
.postalCodeInstruction {
	color: #6E95CC;
	font-size: .7rem
}

@media (min-width:650px) {
	.postalCodeInstruction {
		margin-left: 10px
	}
}

/* TODO:テキストボックス再作成後クラスに置き換え */
#zip{
	width: 107px;
}

#zip::-webkit-input-placeholder{
	font-size: 0.86em;
}
#zip::-moz-placeholder {
	font-size: 0.86em;
}
#zip:-ms-input-placeholder {
	font-size: 0.86em;
}

@media (min-width: 650px){
	._lableCoverBase {
	    display: inline-block;
	    max-width: 300px;
	    width: 30%;
	    height: 36px;
	    margin: 0;
	    padding-top: 10px;
	    float: left;
	}
}

@media (min-width: 844px){
	._lableCoverBase {
	    width: 20%;
	}
}


._lableCoverBase {
    display: block;
    min-width:200px;
}
