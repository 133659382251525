/* ヘッダー, 左メニュー */

.logoutImage{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logoutImage > img {
    width: auto;
}
.rightIconContentNotice1{
    width: 24px;
    height: 24px;
    margin-bottom: 0px !important;
}

/**
 * 元navbar.css
*/
.header{
    background-color: #354C60;
    height: 50px;
    color: #fff;
    width: 100%;
}

.header_logo{
    width:160px;
    height: auto;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.headerLogoCover{
    width: 100%;
    height: 50px;
    position: relative;
}
.navbar{
    display: none;
    height: auto;
    text-align: center;
    float: right;
    width: auto;
}

/* -------------------------------------------------- */
/* note: Humburger ICON */
.menuIcon{
    width: 20px;
    height: 19px;
    position: relative;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);

}
.menuIconCickable{
    height: 50px;
    width: 50px;
    position: relative;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    }
.menuIconCover{
    width: 60px;
    height: 50px;
    position: absolute;
    right:0;
    top:0;
    }
.menuIcon::before{
        content: "";
        position: absolute;
        top:0;
        left:0;
        width: 20px;
        height: 3px;
    border-radius: 1.5px;
        background-color: #fff;
        -webkit-transform-origin:0 0;
                transform-origin:0 0;
        -webkit-transition: -webkit-transform .3s ease-out;
        transition: -webkit-transform .3s ease-out;
        transition: transform .3s ease-out;
        transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    }
.menuIcon_middle{
        position: absolute;
        top:8px;
        left:0;
        width: 20px;
        height: 3px;
    border-radius: 1.5px;
        background-color: #fff;
        -webkit-transition: all .3s ease-out;
        transition: all .3s ease-out;
        -webkit-transform-origin:0 50%;
                transform-origin:0 50%;
    }
.menuIcon::after{
        content: "";
        position: absolute;
        bottom:0;
        left:0;
        width: 20px;
        height: 3px;
    border-radius: 1.5px;
        background-color: #fff;
        -webkit-transform-origin:0 100%;
                transform-origin:0 100%;
        -webkit-transition: -webkit-transform .3s ease-out;
        transition: -webkit-transform .3s ease-out;
        transition: transform .3s ease-out;
        transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    }

.cross::before{
-webkit-transform:rotate(45deg) scaleX(1.25);
        transform:rotate(45deg) scaleX(1.25);
}
.cross .menuIcon_middle{
    opacity:0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
}

.cross::after{
    -webkit-transform:rotate(-45deg) scaleX(1.25) translateY(1px);
            transform:rotate(-45deg) scaleX(1.25) translateY(1px);
}
.menuBlock{
    display: none;
    width:100%;
    height:100%;
    position:absolute;
    z-index:2;
}

/* ------------------------------------------------------- */
/* LeftMenu Start */
.leftMenu{
    width:0;
    height: 100%;
    position: fixed;
    left:0;
    top:0;
    /* background-color: rgba(0,0,0,.8); */
    background-color: #fff;
    z-index: 2;
    -webkit-transition: width .7s ease-in-out; /* Safari */
    transition: width .7s ease-in-out;
    overflow: hidden;
    color: #000000;
    text-align: center;
}
.leftMenu_body{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 5;
    background: #fff;
}

.leftMenuOpen{
    display: block;
    width:100% !important;
}

.leftMenu_top{
    width:100%;
    height: 100%;
    background-color: #000;
    line-height: 60px;
    font-size: 20px;
    position: relative;
}

.tapArrowBox{
    width: 43px;
    height: 43px;
    overflow: hidden;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);
    position: absolute;
    right: -22px;
    top:8px;
    z-index: 10;
}
.triangleClass{
    width: 60px;
    height: 60px;
    -webkit-transform-origin:0 0;
        transform-origin:0 0;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);
    background-color: #354C60;
}

/* ------------------------------------------------------- */
/* If Medium Size */
@media (min-width:601px) {
    .headerLogoCover{
    width: 160px;
    float: left;
    margin-left: 20px;
    }
    .navbar{
    display: inline-block;
    }

    .menuIcon{display: none;}
    .menuIconCover{
    display: none;
    }
    .leftMenu{
    width:0 !important;
    }
}

/**
 *
 * 元navbarDashBoard.css
 *
*/
.navbar_item_dashboard{
    display: block;
    outline: none;
    height: 30px;
    line-height: 31px;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 400;
    text-decoration: none;
    width: 130px;
    float: right;
    padding: 0;
    margin-top: 10px;
    background-color: #6E95CC;
    font-weight: 500;

}

/* NOTIFICATIONS  */

.navbar_item_special{
    display: block;
    outline: none;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 400;
    text-decoration: none;
    width: 40px;
    float: right;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
}

.rightIconContentNotice{
    width: 20px;
    height: 24px;
    margin: 0 !important;
}

.rightIconContentCover{
    position: relative;
    width: 40px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainItems{
    display: none;
}
.mainItemsOnClick{
    color:#6E95CC;
    cursor: default;
}
.linkToMypage {
    color: #f0f0f0;
    cursor: pointer;
}

.headerLogoCover a:hover {
    cursor: pointer;
}


/* -------------------------------------------------- */
/* note: Humburger ICON */

/* ------------------------------------------------------- */
/* SECOND BAR ITEMS */
.hiddenItemBar{
    background: #354C60;
    height: 50px;
    color: #fff;
}


.mainItems_x{
    cursor: pointer;
    display: block;
    margin-left: 0;
    float: left;
    height: 50px;
    line-height: 50px;
    width: auto;
    text-align: center;
    font-weight: 500;
    margin-left: 10px;
    font-size: 14px;
}

.xItemCover{
    float: right;
    width: 80px;
    height: 50px;
    position: relative;
}
.LoginLink_xxx{
    background: #6E95CC;
    width: 97px;
    height: 30px;
    position: absolute;
    left: -65px;
    top: 9px;
    font-size: 12px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}

.mainContainerDashboard{
     width: 100%;
     height: calc(100% - 100px);
}
.mainContainerLeftDashboard{
    display: none;
}

.font_dashboard_blue {
    text-align: left;
    color: #85a5d3;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.font_dashboard_black {
    text-align: left;
    color: #000000;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

/* ------------------------------------------------------- */
@media (max-width:350px) {
  .mainItems_x{
      font-size: 9px;
      margin-left: 5px;
  }
}
@media (max-width:600px){
    .xItemCover{
        width:120px
    }

}
/* If Medium Size */
@media (min-width:601px) {
    .mainContainerDashboard{
        height: calc(100% - 50px);
        overflow: hidden;
        -ms-overflow-style:none;
    }
    .mainContainerLeftDashboard{
        display: block;
         width: 200px;
         height: 100%;
         float: left;
         overflow-y: auto;
         overflow-x: hidden;
    }
    .hiddenItemBar{
        display: none;
    }

    .mainItems{
        display: block;
        margin-left: 0;
        float: left;
        height: 50px;
        line-height: 50px;
        width: auto;
        text-align: center;
        font-weight: 500;
        margin-left: 20px;
    }

    .LoginLink_x{
        display: block;
        cursor: pointer;
    }

    .proImage{
        width:80px;
        height: 80px;
        margin-left: 59px;
        margin-top: 10px;
        margin-bottom: 10px;
        object-fit: contain;
    }
    .detailTab{
        margin-left: 20px;
        margin-bottom: 30px;
        cursor: pointer;
    }
    .pro_text_logout{
        margin-left: 50px;
        cursor: pointer;
    }
    .mainContainerLeft_prop{
        margin-top: 10px;
        margin-bottom: 20px;
        position: relative;
        text-align: center;
    }
    .mainContainerLeft_details{
        margin-top: 20px;
    }
}

div.iframeDiv {
    height: 100%;
	overflow-y:auto;
}

div.iframeDivSub {
    overflow-x: hidden;
    -webkit-overflow-scrolling: auto;
    width: 100%;
}

@media (min-width:601px) {
    div.iframeDiv {
        float: right;
        width: calc(100% - 200px);
        height: 100%;
    }
}

.leftImgInHidden{
    width:100px;
    height: 100px;
    margin:auto;
    margin-top: 5px;
    object-fit: contain;
}
.leftMenuListItems{
    /*height: 500px;*/
    color: #000;
    text-align: left;
    /*padding-left: 80px;*/
    /*overflow-y: scroll;*/
    background-color: #fff;
}
.leftMenuListItems_des{
    overflow: hidden;
    width: 220px;
    margin: auto;
    cursor: pointer;
}

.leftMenuListItems_img{
    width: 20px;
    height: 20px;
    margin-right: 15px;
}
.leftMenuProfile_topic{
    color: #000000;
    width: 200px;
    margin: auto;
    line-height:30px;
}

.leftMenuProfile{
    width:100%;
    text-align:center;
}
.badgeCover{
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.badge1[data-badge]:after {
    content:attr(data-badge);
    position:absolute;
    top:-7px;
    right:-10px;
    font-size:.9em;
    background:red;
    color:white;
    width:auto;
    min-width:18px;
    height:18px;
    text-align:center;
    line-height:18px;
    border-radius:50%;
    box-shadow:0 0 1px #333;
    word-break: normal;
}



/**
 *
 * 元InformationPilot.css
 *
*/
.logout {
    font-weight: 400;
    width: 100px;
    height: 40px;
    line-height: 38px;
}

/**
 *  新規追加
 */
.myPageContentTitleMain {
    font-weight: bold;
    font-size: 36px;
    color: #E4E4E4;
}

.myPageContentTitle {
    font-size: 14px;
    padding-top: 20px;
    margin-left: 20px;
}