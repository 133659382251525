/* 標準部品 */
/* 基本の形 */
.buttonshape {
    display: inline-block;
    text-align: center;
    margin: 5px auto;
	cursor: pointer;
	-webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* 色ホワイト */
.buttonwhite {
    background: #ffffff;
	color:#000;
    border: 1px solid #000;
}

/* 色グレー */
.buttongray {
    background: #f5f5f5;
	color:#000;
    border: 1px solid #000;
}

/* 色ブルー */
.buttonblue {
	background-color:#6D94CC;
	color:#fff;
	border:1.5px solid #6D94CC;
}

/* 色グリーン */
.buttongreen {
	background-color:#53CEA8;
	color:#fff;
    border:0;
    font-weight: 600;
}

/* 色グレー */
.buttonnegative {
    background: #CCC;
	color:#FFF;
    border: 0;
    font-weight: 600;
    cursor: default;
}

.buttonskeleton {
    border: 2px solid #f5f5f5;
    padding: 10px 80px 10px 80px;
}

/* 画面 */
.loginButtonFull {
    width: 100%;
    height: 40px;
    line-height: 40px;
}

/* ダイアログ */
.buttondialog {
    max-width: 180px;
    width:100%;
    height: 40px;
    line-height: 41px;
    margin-left:5px;
    margin-right:5px;
}

.buttondialoglarge {
    max-width: 370px;
    width:100%;
    height: 40px;
    line-height: 40px;
    margin-left:5px;
    margin-right:5px;
}

/* 各画面ごとの定義 */
.registrationButton {
    max-width:300px;
    width: 90%;
    height: 40px;
    line-height: 40px;
    margin-left:5px;
    margin-right:5px;
}

.registrationButtonSmall {
    width: 170px;
    height: 40px;
    line-height: 40px;
    margin-left:5px;
    margin-right:5px;
}

.registrationButtonVerySmall {
    width: 100px;
    height: 40px;
    line-height: 40px;
    margin-left:5px;
    margin-right:5px;
}

.registrationButtonLink {
    border: none;
    text-decoration: underline;
    font-weight: 500;
	cursor: pointer;
}

.registrationButtonLinkNoEnter {
    display:inline-block;
    border: none;
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
    line-height: 36px;
}

.registrationButtonTop {
    right:0px;
    width:200px;
    height: 40px;
    line-height: 40px;
    margin-left:5px;
    margin-right:5px;
}

.backButtonLink{
	background-image: url("../images/back.svg");
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 7px 12px;
    border: none;
    text-decoration: underline;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    width: 50px;
}

