/*
 * _basicHeader1.css
 * AirShareBase.css
 * InformationPilot.css → 破棄
 * InformationUser.css
 * RegistrationBase.css
 * RegistrationOwner.css
 * RegistrationPilot.css
 * RegistrationUser.css
*/

/*
 * _basicHeader1.css
*/
.airShareLogo{
	width: 160px;
	height: auto;
	display: block;
	margin: auto;
  }
  
.airShareLogo_cover{
	margin:30px 0;
}

/*
 * InformationUser.css
 * TODO:flexでよい
*/
@media (max-width: 870px) {
    div .iframeDivSub .formBtnBoxThreeTitle .formBtnItemLeftBase {
        width: 90px;
    }
}

@media (max-width: 800px) {
    div .iframeDivSub .formBtnBoxThreeTitle .formBtnItemLeftBase {
        width: 70px;
    }
}

@media (max-width: 710px) {
    div .iframeDivSub .formBtnBoxThreeTitle .formBtnItemLeftBase {
        width: 10px;
    }
}

@media (max-width: 652px) {
    div .iframeDivSub .formBtnBoxThreeTitle .formBtnItemLeftBase {
        width: 0px;
    }
}

/*
 * AirShareBase.css
*/

.formCover {
	max-width:100%;
	margin:auto
}

/* 登録系画面のタイトル領域 */
.userIconCover {
	max-width:95%;
	margin:auto;
	margin-bottom:40px;
	margin-top:30px;
	font-size:24px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.userIcon {
	width:40px;
	height:40px;
	margin: 0 !important;
}

.userIcon_text {
	color:#6E95CC;
	font-weight:700;
	font-size:19px;
	position:relative;
	margin-left: 0.5em;
}

/* FORM */
.formRegistration {
	background-color:#fff;
	width:95%;
	max-width:1000px;
	margin:0 auto;
	padding:10px
}

.formRegistrationCoverInside {
	max-width:100%;
	margin-top:5px;
}

/* 各ラベル表現 */
.lableCoverBase {
	display:block;
	margin:15px 0
}
.lableCoverBase.nolabel {
	height:0;
	margin-top: 0;
}
.lableCoverBodyBase {
	display:block;
	margin:15px 20px;
	word-wrap: break-word;
}
.lableCoverSub {
	display:none;
}

.lblbase {
	display:inline-block;
	margin:0;
}

@media (min-width:650px) {
    .lblbase {
    	margin-left:10px;
    	margin-right:10px;
    }
    .lblbase_noenter {
    	display:inline-block;
    }
	.lableCoverBase {
		display:inline-block;
		width:240px;
		height:36px;
		margin:0;
		/*margin-right:10px;*/
		padding-top:10px;
		float:left
	}
	.lableCoverBodyBase {
		display:inline-block;
		max-width:600px;
		width:70%;
		margin:0;
		padding-top:10px;
		float:left
	}
	.lableCoverSub {
		display:inline-block;
		height:36px;
		margin:0;
		margin-right:10px;
		padding-top:10px;
		float:left
	}
}

/* 必須アイコン */
.required {
	display:none;
	background-color:#DD4B40;
	color:#fff;
	padding: 0px !important;
	margin-left:10px;
	font-size:10px;
	font-weight:700;
	height: 16px;
	width: 35px;
	line-height: 16px;
	text-align: center;
}
.required_section {
	display:inline-block;
	background-color:#DD4B40;
	color:#fff;
	padding:0px !important; 
	margin-left:10px;
	margin-bottom:10px;
	font-size:10px;
	font-weight:700;
	height: 16px;
	width: 35px;
	line-height: 16px;
	text-align: center;
}

@media (min-width:650px) {
	.required {
		display:inline-block
	}
}

/* ボタン系 */

.formBtnBoxThreeTitle {
	display: table;
    table-layout: fixed;
    text-align: center;
	width:100%;
	margin:0px auto 0px;
	padding-top:20px;
}
.formBtnBoxThree {
	display: table;
    table-layout: fixed;
    text-align: center;
	width:100%;
	margin:30px auto 50px;
	padding-top:20px;
}
.formBtnBoxTwo {
	display: table;
    table-layout: fixed;
    text-align: center;
	width:100%;
	margin:30px auto 50px;
	padding-top:20px;
}
.formBtnBoxOne {
	display: table;
    table-layout: fixed;
    text-align: center;
	width:100%;
	margin:10px auto 10px;
	padding-top:10px;
}
.formBtnItem {
    display: block;
}

.formBtnItemLeftBase {
    align:center;
    text-align:center;
}
.formBtnItemMiddleBase {
    align:center;
    text-align:center;
}
.formBtnItemRightBase {
    align:center;
    text-align:center;
}

@media (min-width:650px) {
    .formBtnItem {
        display: table-cell;
        vertical-align: middle;
    }
    .formBtnItemLeftBase {
        text-align:left;
    }
    .formBtnItemMiddleBase {
        text-align:center;
    }
    .formBtnItemRightBase {
        text-align:right;
    }
    .formBtnBoxThree .formBtnItemLeftBase {
		text-align:unset;
		width: 30%;
    }
    .formBtnBoxThree .formBtnItemMiddleBase {
		text-align:unset;
		width: 30%;
    }
    .formBtnBoxThree .formBtnItemRightBase {
		text-align:unset;
		width: 40%;
    }
	.formBtnBoxThree .formBtnItemRightBase .registrationButton {
		max-width: none;
		width: 100%;
	}
    .formBtnBoxThreeTitle {
        max-width:950px;
    	width:90%;
    }
    .formBtnBoxThree {
		display: table;
        max-width:950px;
    	width:90%;
    }
    .formBtnBoxTwo {
        max-width:700px;
    	width:90%;
    }
    .formBtnBoxOne {
        max-width:600px;
    	width:90%;
    }
}

/* 確認画面 */
.profile_image {
    max-width: 100px;
    max-height: 100px;
    margin-left: 10px;
    margin-top: 10px;
    object-fit: contain;
}

.topText {
	text-align:center;
	margin-bottom:40px;
	/*margin-top:30px;*/
	font-size:24px;
}

/* textarea */
.textareaShow {
    margin:0px !important;
}

.textareaControl {
	width:100%;
	height:104px;
	resize: none;
}

.textareaControl:focus {
	/*
	border-color:#80bdff;
	box-shadow:0 0 0 .2rem rgba(0,123,255,.25)
	*/
}


/*
 * RegistrationBase.css
*/

/* 各セクションのタイトル領域 */
.SectionTitle {
	font-size:16px;
	margin-top:10px;
	margin-bottom:20px;
	border-bottom:thin solid #e6e3e3;
	margin-left:10px;
	font-weight:700
}
.SectionTitle~.clearfix{
	margin-left: 20px;
}
@media (max-width: 650px){
    .SectionTitle~.clearfix{
    	margin-left: 0px;
    }
}

/* 各入力情報 */
.formControlBox {
	display:inline-block;
	width:100%;
	margin:auto
}

.formControlThrid {
	display:inline-block;
	max-width:85%;
	width:80%;
	height:36px;
	padding:.375rem .75rem;
	font-size:1rem;
	line-height:1.5;
	color:#CCC;
	background-color:#F5F5F5;
	background-clip:padding-box;
	border:1px solid #E4E4E4;
	border-radius:3px;
	transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.formControlHalfPlus {
	display:inline-block;
	width:40%;
	height:36px;
	padding:.375rem .75rem;
	font-size:1rem;
	line-height:1.5;
	color:#CCC;
	background-color:#F5F5F5;
	background-clip:padding-box;
	border:1px solid #E4E4E4;
	border-radius:3px;
	transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.formControlHalf {
	display:inline-block;
	max-width:100%;
	width:100%;
	height:36px;
	padding:.375rem .75rem;
	font-size:1rem;
	line-height:1.5;
	color:#CCC;
	background-color:#F5F5F5;
	background-clip:padding-box;
	border:1px solid #E4E4E4;
	border-radius:3px;
	transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (min-width:650px) {
	.formControlBox {
		position: relative;
		max-width:700px;
    	min-width:400px;
	  }
	.formControlHalfPlus {
		display:inline-block;
    	/*max-width:200px;*/
    	width:15%;
	}
	.formControlHalf {
		display:inline-block;
    	max-width:370px;
		min-width:170px;
    	width:40%;
	}
	.formControlThrid {
		display:inline-block;
    	/*max-width:200px;*/
    	width:30%;
	}
}

/* 各セレクトボックス */
.selectorsBox {
	display:inline-block;
	width:100%;
	margin:auto
}
.selectorsBoxLarge {
	display:inline-block;
	width:100%;
	margin:auto
}

.selectorsHalf {
	float:left;
	display:block;
	width:45%;
	height:36px;
	padding:.375rem 24px .375rem .75rem;
	font-size:1rem;
	line-height:1.5;
	color:#000;
	background-color:#F5F5F5;
	background-clip:padding-box;
	border:1px solid #E4E4E4;
	border-radius:3px;
	transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.selectorsHalf:focus {
	color:#495057;
	background-color:#fff;
	border-color:#61D2AF;
	outline:0
}

.selectorsHalf:not(:first-child) {
	margin-left:10px
}

.selectorsLarge {
	float:left;
	display:block;
	width:45%;
	height:36px;
	padding:.375rem 24px .375rem .75rem;
	font-size:1rem;
	line-height:1.5;
	color:#000;
	background-color:#F5F5F5;
	background-clip:padding-box;
	border:1px solid #E4E4E4;
	border-radius:3px;
	transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.selectorsLarge:focus {
	background-color:#fff;
	border-color:#61D2AF;
	outline:0
}

.selectorsLarge:not(:first-child) {
	margin-left:10px
}

.selectorsFull {
	float:left;
	display:block;
	width:100%;
	height:36px;
	padding:.375rem 24px .375rem .75rem;
	font-size:1rem;
	line-height:1.5;
	color:#000;
	background-color:#F5F5F5;
	background-clip:padding-box;
	border:1px solid #E4E4E4;
	border-radius:3px;
	transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	margin-left: 2px;
}

.selectorsFull:focus {
	color: #000;
	background-color:#fff;
	border-color:#61D2AF;
	outline:0
}

.selectorsFull:not(:first-child) {
}

@media (min-width:650px) {
	.selectorsBox {
		width:400px;
	}
	.selectorsBoxLarge {
		width:400px;
	}
	.selectorsHalf {
		width:150px;
	}
	/* .selectorsFull {
        width:97%;
	} */
}

/* 各ラジオボタン */
.radioBox {
	display:inline-block
}


/* チェックボックス */
.checkboxBox {
	display:inline-block
}

.checkboxAdjustTextBox {
    padding-top:22px;
    top:0px;
}

@media (min-width:650px) {
	.checkboxBox {
		padding-top:8px
	}
}

/* アップロード */
/* TODO:削除 */
.uploadHidden {
	height:36px;
	width:70%;
	margin-left:98px;
	border-radius:0 3px 3px 0;
	background-color:#F5F5F5;
	border:none
}

/* ボタン系 */
.formBtnBox {
	display: table;
    table-layout: fixed;
    text-align: center;
	width:100%;
	margin:30px auto 50px;
}
.formBtnItem {
    display: block;
}

@media (min-width:650px) {
    .formBtnItem {
        display: table-cell;
        vertical-align: middle;
    }
    .formBtnBox {
    	width:90%;
    }
}

/* 確認画面 */
.profile_image {
    max-width: 100px;
    max-height: 100px
}

.topText {
	text-align:center;
	margin-bottom:40px;
	margin-top:30px;
	font-size:24px;
}

/* 下書き保存 */
.floatdraft {
	color:#000;
	font-size:12px;
	padding:10px 20px;
	transition-duration:.5s;
	-webkit-box-shadow:0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
	-moz-box-shadow:0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
	box-shadow:0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
	position: fixed;
    top: 5px; 
    right: calc(50% - 500px);
    background-color: rgba(255,255,255,0.5);
}
.floatdraft.onmypage {
	top: 55px; /*header(50px) + hiddenItemBar(0px)*/
    right: calc(50% - 600px);
}
.floatdraft.top_position {
	top: 130px; /*userIconCover(110px) + airShareLogo_cover(30px+13.297px) - 35*/
}
.floatdraft.onmypage.top_position {
	top: 135px; /*header(50px) + hiddenItemBar(0px) + userIconCover(110px) - 35*/
}

.floatdraftSave {
	display: block;
}

@media(max-width:1253px){
	.floatdraft.onmypage {
		right: 2.5%;
	}
	.onmypage .floatdraftSave {
        width: 80px;
        height: 30px;
        line-height: 30px;
        margin-left: 20px;
	}
	.onmypage .floatdraftDelete {
		width: 80px;
		height: 30px;
		line-height: 30px;
		margin-left: 20px;
	}
	.onmypage .floatdraftDelete img {
		width: 25px;
		height: 25px;
	}
	.onmypage .floatdraftDelete span {
		display: none;
	}
}
@media(max-width:1050px){
	.floatdraft {
		right: 2.5%;
	}
}
@media(max-width:600px){
    .floatdraft.onmypage{
		top: 105px; /*header(50px) + hiddenItemBar(50px)*/
		width: 125px;
		padding-left: 0;
    }
	.floatdraft.onmypage.top_position {
		top: 175px; /*header(50px) + hiddenItemBar(50px) + userIconCover(110px) - 35*/
	}

	.floatdraftSave {
        width: 80px;
        height: 30px;
        line-height: 30px;
        margin-left: 20px;
	}
	.floatdraftDelete {
		width: 80px;
		height: 30px;
		line-height: 30px;
		margin-left: 20px;
	}
	.floatdraftDelete img {
		width: 25px;
		height: 25px;
	}
	.floatdraftDelete span {
		display: none;
	}
}

/*
* RegistrationOwner.css
*/


/**
 * RegistrationPilot.css
*/
.required_section{
	vertical-align: text-top;
}

/**
* NoLoginPage & MyPage 共通
*/
.myPageContentFrame{
	width: 90%;
	max-width: 1500px;
	margin: auto;
	padding: 15px;
}