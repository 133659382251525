/* 共通 */
.selectboxCommon {
	padding-left: .75rem;
    font-size:1rem;
    line-height:1.5;
    border-radius:3px;
    transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	/* 三角 */
	background-image: url("../images/selectboxButton.svg");
	background-position: right 10px center;
	background-size: 8.4px;
}

/* デフォルト */
.selectboxDefault {
    width: 300px;
	height:36px;
}

/* デフォルト */
.selectboxFill {
    width: 100%;
	height:36px;
}


/*旧*/

.selectorsHalf, .selectors, .selectorsFull, .selectorsLarge, .seatsSelectBox, .timeSelectBox, .timeSelectBoxH, .timeSelectBoxM {
	background-image: url("../images/selectboxButton.svg");
	background-position: right 10px center;
	background-size: 8.4px;
}

/* 標準部品 */
/* 基本の形 */
.selectors {
	float:left;
	display:block;
	height:36px;
	padding:.375rem 24px .375rem .75rem;
	font-size:1rem;
	line-height:1.5;
	color:#000;
	background-color:#F5F5F5;
	background-clip:padding-box;
	border:1px solid #E4E4E4;
	border-radius:3px;
	transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.selectors:focus {
	color:#495057 !important;
	background-color:#fff !important;
	border-color:#61D2AF !important;
	outline:0 !important
}

.selectors:not(:first-child) {
	margin-left:10px;
}

.selectorsnotfirst {
	color:#495057 !important;
	background-color:#fff !important;
	outline:0 !important;
}

.selectorserror {
	background-color:#FCECEB!important;
	border-color:#E05A50!important
}

.selectorserror:focus {
	color:#000!important;
	background-color:#FCECEB!important;
	border-color:#E05A50!important;
	outline:0!important;
	box-shadow:0 0 0 .2rem rgba(255,0,0,0.25)!important
}

/* サイズ */
.date {
    width:90px !important;
}

/* 色 テキストボックスと共通化可能 */
.selectboxColor_noinput {
    color: #ccc;
	background-color:#f5f5f5;
    border:1px solid #E4E4E4;
}
.selectboxColor_input {
    color: #333;
	background-color:#fff;
    border:1px solid #E4E4E4;
}
.selectboxColor_input:focus {
    border:1px solid #61D2AF;
    outline: 0;
}
.selectboxColor_Error {
    color: #333;
	background-color:#FCECEB;
    border:1px solid #E05A50;
}
.selectboxColor_Error:focus {
    outline: 0;
	box-shadow:0 0 0 .2rem rgba(255,0,0,0.25);
}
