

/*-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------

利用日選択

-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
.overBlackPanel{
    position: fixed;
    width:100vw;
    height:100vh;
    top: 0;
    left: 0;
    z-index: 9993;
    background: rgba(51,51,51,0.5);
}
.showSelectDepartureTimeDialog{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.showSelectDepartureTimeDialogContent{
    width:640px;
    background: #ffffff;
    padding: 20px 50px;
}
.selectDepartureTimeDialogLabel{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.selectDepartureTimeDialogAttention{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color:#999999;
}
.selectDepartureTimeDialogDatePanel{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content:space-between;
    background: #f5f5f5;
    padding: 5px 20px;
}
.timeSelectBox, .timeSelectBoxH, .timeSelectBoxM {
    width: 80px;
}
.couponSelectBox {
    width: 100%;
}
.timeSelectBox, .timeSelectBoxH, .timeSelectBoxM ,.halfTimeSelectBox, .couponSelectBox{
    padding-left: 20px;
    padding-right: 24px;
    height: 40px;
    border: solid 1px #707070 !important;
	background-color:#fff;
    border-radius: 3px;
    margin: 5px 0;
}
.timeSelectionConfirm{
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    flex-shrink: 0;
}

.dateSelectionContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dateSelectionHeader{
    width: 100%;
    min-height: 70px;
    background-color: #FFFFFF;
    display: flex;
    justify-content:space-between;
    flex-shrink: 0;
    color: #333333;
    filter: drop-shadow(0px 3px 1px rgba(0,0,0,0.1));
    position: relative;
    z-index: 1002;
    flex-wrap: wrap;
    padding : 8px 0px 8px 0px;
}

.dateContainer{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-shrink: 1;
}

.dateSelectionMonth{
    margin-left: 20px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-grow: 0;
    max-width: 380px;
    width: 100%;
}

.disableMonth{
    color: #CCCCCC;
    border: solid 1px #CCCCCC;
}

.dateValueSpace{
    min-width: 202px;
    justify-content: flex-start;
}

.editingDate{
    border-bottom: 2px solid #6E95CC;
}

.dateSelectionConfirm{
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    flex-wrap: wrap;
    flex-grow: 9999;
}

.dateSelectCalender{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: column;
    background: #E4E4E4;
    overflow-y: auto;
}
.calenderSpace{
    width: 1px;
    height: 1px;
    flex-shrink: 0;
}
.calenderHeader{
    flex-shrink: 0;
    height: 50px;
    width:100%;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: nowrap;
}
.calenderHeaderLabel{
    width:100%;
    height:100%;
    flex-shrink: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    color: #333333;
}
.saturdayLabel{
    color:#6E95CC;
}
.sundayLabel{
    color:#DD4B40;
}
.calenderValueContent{
    flex-shrink: 1;
    height: 100%;
    min-height: 70px;
    width:100%;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: nowrap;
}
.dateCell{
    width:100%;
    height:100%;
    display: flex;
    flex-shrink: 1;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    font-weight: 600;
    padding: 5px;
    position: relative;
}
.disableDateColor{
    background-color: #F5F5F5;
    color: #CCCCCC;
}
.enableDateColor{
    background-color: #ffffff;
    color: #333333;
    cursor: pointer;
}
.selectedDateCell{
    border: 5px solid #6E95CC;
    padding: 0px;
}
.dateLabel{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: 2px;
}

.whileColor{
    background: #b5c9e5;
}

.centerContent{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
}
.bottomContent{
    width: 100%;
    height: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;        /* TODO:ずれるかも？ */
}
.nothingFlightDateContent{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -5px;
}
.nothingFlightDateIcon{
    width: 100%;
    height: 100%;
    max-width: 14px;
    max-height: 14px;
    margin: 0px !important;
}
.icon20pxContainer{
    width: auto;
    height: 100%;
    max-width: 20px;
    max-height: 20px;
    flex-shrink: 1;
    display: flex;
    align-items: center;
}
.flexibleSpace10px{
    width: 100%;
    max-width: 10px;
    flex-shrink: 2;
}
.bottomNumber{
    flex-shrink:0;
}

.dateValueBox{
    width : 130px;  /* 元150px */
    height : 100%;
    font-weight: bold;
    display : flex;
    justify-content: center;
    align-items: center;
}

.ymdLabel{
    white-space: nowrap;
}

@media (max-width:1023px) {
    .overBlackPanel{
        z-index: 1003;
    }
}
/* ダイアログの幅 */
@media(max-width:645px){
    .showSelectDepartureTimeDialogContent{
        width:100%;
        padding: 20px 20px;
    }
}
@media(max-width:550px){
    .selectDepartureTimeDialogDatePanel{
        flex-direction: column;
        height: auto;
        width: auto;
        
    }
    .ymdLabel{
        padding-bottom:10px;
    }
}

/* 出発日の枠減らす */
@media(max-width:445px){
    .selectedValue{
        /*padding: 0px 5px 0px 5px;*/
    }

    .dateValueSpace{
        min-width: 182px;
    }
    /*
    .dateLabel{
        width: 20px;
        height: 20px;
    }
    */
    
}

/* iphone6 */
@media(max-width:375px){
    /* ドライバー/車両数の文字小さく */
    .bottomNumber{
        font-size:3vw;
    }
}

/* 縦横短いほうに合わせる */
@media(orientation: landscape){
    .confirmDateIcon{
        width:auto;
        height: 60%;
    }
}

@media(orientation: portrait){
    .confirmDateIcon{
        width:60%;
        height: auto;
    }
}
