/* 色 */
.textboxColor_noinput {
    color: #ccc;
	background-color:#f5f5f5;
    border:1px solid #E4E4E4;
}
.textboxColor_input {
    color: #333;
	background-color:#fff;
    border:1px solid #E4E4E4;
}
.textboxColor_input:focus {
    border:1px solid #61D2AF;
    outline: 0;
}
.textboxColor_Error {
    color: #333;
	background-color:#FCECEB;
    border:1px solid #E05A50;
}
.textboxColor_Error:focus {
    outline: 0;
	box-shadow:0 0 0 .2rem rgba(255,0,0,0.25);
}

/* 共通 */
.textboxCommon {
    padding:.375rem .75rem;
    font-size:1rem;
    line-height:1.5;
    border-radius:3px;
    transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* デフォルト */
.textboxDefault {
    width: 300px;
}

/* パイロット運航チェックリスト */
.textbox_pilotCheckList {
	width: 200px;
}
.textarea_pilotCheckList {
	width: 90%;
	height: 70px;
	max-width: 90%;
	min-width: 90%;
}

/* 同乗者情報 */
.textbox_passenger {
	width: 200px;
}
.textbox_passenger_half {
	width: 100px;
}
.textbox_passenger_double {
  width: 400px;
}

/* クレジットカード */
.textbox_credit_full {
    width: 100%;
}
@media (min-width:650px) {
    .textbox_credit_full {
        width: 240px;
    }
}

.textbox_credit_half {
	width:55px;
}
.formControlTextAreaHeight300px {
	display:inline-block;
	max-width:100%;
	min-width: 100%;
	width:100%;
	height:300px;
	padding:.375rem .75rem;
	font-size:1rem;
	font-weight: normal;
	line-height:1.5;
	color:#CCC;
	background-color:#F5F5F5;
	background-clip:padding-box;
	border:1px solid #E4E4E4;
	border-radius:3px;
	transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

/*旧*/

/* 標準部品 */
/* 部品の形 */
.inputshape {
    padding:.375rem .75rem;
    font-size:1rem;
    line-height:1.5;
    background-clip:padding-box;
    border:1px solid #E4E4E4;
    border-radius:3px;
    transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.inputshape:focus  {
    color: #000 !important;
    background-color: #fff !important;
    outline: 0 !important;
	border-color:#61D2AF;
}

/* 入力済み */
.inputs {
    color: #000 !important;
    background-color: #fff !important;
    outline: 0 !important
}
/* 未入力 */
.noinput  {
    color:#CCC !important;;
    background-color:#F5F5F5 !important;
    outline: 0 !important;
}

/* バリデート */
.inputerror {
	background-color:#FCECEB !important;
    border:1px solid #E05A50 !important;
}

.inputerror:focus {
    color:#000;
	background-color:#FCECEB !important;
    border:1px solid #E05A50 !important;
	outline:0 !important;;
	box-shadow:0 0 0 .2rem rgba(255,0,0,0.25) !important;
}


/* 各画面ごとの定義 */

/* 登録編集画面 */
.formControl7 {
    width:92px;
    height:36px;
}
.formControl15 {
	width:172px;
    height:36px;
}
.formControl20 {
	width:232px;
	height:36px;
}

.formControlFull {
	width:100%;
	height:36px;
}

@media (min-width:650px) {
	.formControlFull {
		display:inline-block;
    	width:85%;
	}
    .formControl7 {
		display:inline-block;
    }
    .formControl15 {
		display:inline-block;
    }
    .formControl20 {
		display:inline-block;
    }
}
