.noLoginLandingContent{}
.noLoginLandingContent > .myPageContentTitle{
	width: 90%;
	max-width: 1500px;
	margin: auto;
	padding-left: 15px;
}

/** 
 *  検索 \.(.)
 *  置換 .top\u$1
 */

/* ヘッダー */
.topContentBox {
	width: 100%;
	height: auto;
}

.topLoginContentBox {
	width: 100%;
	min-height: 100px;
	padding: 30px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	letter-spacing: 0.1em;
}
.topLoginContentBox a {
	text-decoration: none;
}

.topHeaderLogo{
	width: 160px;
	height: auto;
	display: flex;
	padding: 10px 0;
}
.topHeaderRight{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-grow: 9999;
	font-weight: bold;
	padding: 10px 0;
}
.topHeaderMenu{
	margin-right: 2em;
}
.topHeaderLogin{
	width: 120px;
	height: 36px;
	border: 1px solid #333;
	display: flex;
	justify-content: center;
	align-items: center;
}
.topHeaderLogoImg{
	width:100%;
	height: auto;
}

/** レスポンシブ */
@media(max-width: 700px) {
	/** ヘッダー */
	.topLoginContentBox{
		display: block;
		padding: 20px 0;
	}
	.topHeaderLogo{
		margin: auto;
		width: 80%;
		padding: 20px 0;
	}
	.topHeaderLogoCover{
		width: 100%;
	}
	.topHeaderMenu {
		display: none;
	}

	.topHeaderRight{
		padding: 20px 0;
		display: block;
	}
	.topHeaderLogin {
		width: 50%;
		height: 50px;
		margin: auto;
	}
}