.subCategoryTriangle {
    width: 0;
    height: 0;
    border-left: 0.5em solid transparent;
    border-right: 0.5em solid transparent;
    border-bottom: 1em solid #333;
    transition: transform 0.2s ease-in;
}

.subCategoryBox {
    transition: height 0.4s ease-in;
    overflow: hidden;
}

.categoryBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;
}

.FAQmainTitle {
    font-size: 30px;
    font-weight: bold;
}
input:focus {
    outline: 0;
}
.categorySearchList {
    width: 100%;
    max-height: 500px;
    color: #333333;
    white-space: nowrap;
    position: absolute;
    list-style-type: none;
    background: #FFFFFF;
    border: solid 1px rgb(71, 136, 238);
    margin: 0px;
    padding: 4px;
    padding-right: 0;
    overflow: scroll;
    overflow-x: hidden;
    -ms-overflow-style:none;
    left:0;
    top:0;
    margin-top: 40px;
    z-index: 2;
}

.categorySearchList::-webkit-scrollbar{
    width: 4px;
}

.categorySearchList::-webkit-scrollbar-thumb{
    background: #999999;
    border-radius: 2px;
    box-shadow: none;
}
.categorySearchList::-webkit-scrollbar-corner{
    background: #999999;
}

.categorySearchList li {
    width: 100%;
    line-height: 2;
    color: #333333;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.categorySearchListPrefecuture {
    border-top: #999999 solid 1px;
    padding-left: 40px !important;
}

.categorySearchList li:hover {
    background: #dddddd;
}

@media(max-width:600px){

    .FAQmainTitle {
        font-size: 20px;
        font-weight: bold;
    }
    
}