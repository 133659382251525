.landing {
    display: block;
}

.landing_sp {
    display: none;
}

.landingCtl{
    position:absolute;
    top:0;
    margin-top:0px;
    margin-left:auto;
    margin-right:auto;
    z-index:3;
    width:100%;
    height:100%;

}
.landingMenu {
    display:block;
    z-index: 3;
    max-width:1000px;
    margin:40px auto auto auto;
    padding: 0;
}
.imgSightseeing{
    width:20%;
    margin: 10%;
    background:transparent;
}

.landingMenu1 {
    padding: 0;
    border: 0;
    margin: 10% auto 12px auto;
}

.txtSightseeing{
    color:white;
    display: inline-block;
}
.txtSightseeing1{
    font-size:16px;
    font-weight: bold;
}

.landingMenu23 {
    z-index:10;
}

.landingMenuBox{
    width:360px;
    height:150px;
    position:relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    margin: 5px 5px 5px 5px;
    background-color:rgba(0,0,0,0.6);
}

.landingMenuBox:hover {
    background-color: rgba(144, 144, 220,0.6);
    border:2px solid white;
    cursor: pointer;
}

.slideshowOuter {
    height:100%;
    z-index: 1;
}
.slide {
    width: 100%;
    flex-shrink: 0;
    height: 100%;
    object-fit: cover;
}

.hideButton {
    display: none;
}

/* :::::: slideContents :::::: */
.slideshow , .slideshow_stop {
    width:100%;
    height:100%;
    z-index: 1;
    display: flex;
    position: relative;
    background: rgb(75,75,100);
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
}

.slideshow section , .slideshow_stop section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-position:center center;
    background-size:cover;
}

#slide-0l , #slide-1l , #slide-2l , #slideSp-0l , #slideSp-1l , #slideSp-2l {
    opacity:0;
    transform:translateX(-100%);
}
#slide-0 , #slide-1 , #slide-2 ,#slideSp-0 , #slideSp-1 , #slideSp-2 {
    opacity:1;
    transform:translateX(0%);
    transition:transform 1.5s cubic-bezier(.075, .82, .165, 1), opacity 0s ease 0s;
}
#slide-0r , #slide-1r , #slide-2r , #slideSp-0r , #slideSp-1r , #slideSp-2r {
    opacity:0;
    transform:translateX(100%);
}
.slideshow #slide-0l , .slideshow #slide-1l , .slideshow #slide-2l
,.slideshow #slideSp-0l , .slideshow #slideSp-1l , .slideshow #slideSp-2l
,.slideshow_stop #slide-0r , .slideshow_stop #slide-1r , .slideshow_stop #slide-2r
,.slideshow_stop #slideSp-0r , .slideshow_stop #slideSp-1r , .slideshow_stop #slideSp-2r {
    transition:transform 1.5s cubic-bezier(.075, .82, .165, 1), opacity 0s ease 1.5s;
}
.slideshow_stop #slide-0l , .slideshow_stop #slide-1l , .slideshow_stop #slide-2l
,.slideshow_stop #slideSp-0l , .slideshow_stop #slideSp-1l , .slideshow_stop #slideSp-2l
,.slideshow #slide-0r , .slideshow #slide-1r , .slideshow #slide-2r
,.slideshow #slideSp-0r , .slideshow #slideSp-1r , .slideshow #slideSp-2r {
    transition:transform 0s ease, opacity 0s ease 0s;
}



.slideshow section img , .slideshow_stop section img {
    max-width: 100%;
    box-sizing: border-box;
    vertical-align: middle;
}

/* :::::: arrows :::::: */
.arw,
.arw label,
.arw .ico {
    position: absolute;
}
.arw {
    top: auto;
    bottom:auto;
    margin: 0;
    transition: background .3s;
    color:white;
    background-color:transparent;
    z-index:10;
}
#goprev span, #gonext span, #goprevSp span, #gonextSp span {
    position: absolute;
    opacity: 1;
    top: 55%;
    z-index: 20;
}
#goprev img, #gonext img {
    width: 20px;
}
#goprevSp img, #gonextSp img {
    width: 15px;
}

#goprev span, #goprevSp span {
    left:20px;
}
#gonext span, #gonextSp span {
    right:20px;
}
.slidemove0 {
    position:absolute;
    bottom: 10px;
    left:0;
    width:100%;
    height:60px;
    background-color:transparent;
    z-index:1;
}

.slidemove0 .switches {
    position:absolute;
    bottom:20px;
    left:calc(50% - 50px);
    height:20px;
    margin: auto auto 0 auto;
    background-color:transparent;
}



.slidemove0 .radiolabelactive{
    z-index:100;
    margin:10px;
    color:rgba(100,100,255,0.5);
    font-size:20px;
    transition:color 1s ease;
}


.slidemove0 .radiolabel{
    z-index:100;
    margin:10px;
    color:rgba(200,200,200,0.5);
    font-size:20px;
    cursor: pointer;
    transition:color 1s ease;
}

.prev {
    left: 0;
}
.next {
    right: 0;
}
.arw {
    top:0;
    color:white;
}

.arw,
.arw label {
    cursor: pointer;
    width: 50px;
    height: 100%;
}
.arw label {
    top: 0;
    left: 0;
    z-index: 1;
}

/* :::::: mechanism :::::: */
/*
.slideshow #slide-0 ,.slideshow_stop #slide-0
,.slideshow #slideSp-0 ,.slideshow_stop #slideSp-0 {
    position: relative;
}
.slideshow section {
    //animation: autoplay 30s linear infinite;
    animation: autoplay 12s cubic-bezier(.075, .82, .165, 1) infinite;
}
.slideshow .radiolabel {
    animation: autochange 12s linear infinite;
}
@keyframes autoplay {
    0%      { transform: none; z-index:1;opacity:1;}
    15.33%     { transform: none; z-index:1;opacity:1;}
    32.33%     { opacity:1;}
    33.33%     { transform: translateX(-100%); z-index:-1;opacity:0;}
    66.66%     { transform: translateX(-100%); z-index:-1;opacity:0;}
    66.67%   { transform: translateX(100%);z-index:-1;opacity:0;}
    82%     { transform: translateX(100%);z-index:-1;opacity:1;}
    100%    { transform: none; z-index:1;opacity:1;}
}
@keyframes autochange {
    0% {color:rgba(100,100,255,0.5);}
    33.33% {color:rgba(200,200,200,0.5);}
    66.67% {color:rgba(200,200,200,0.5);}
    100% {color:rgba(100,100,255,0.5);}
}
.slideshow #slide-0 ,.slideshow #slideSp-0 ,.slideshow #radiolabel0 ,.slideshow #radiolabel0Sp { animation-delay: 0s; }
.slideshow #slide-2 ,.slideshow #slideSp-2 ,.slideshow #radiolabel2 ,.slideshow #radiolabel2Sp { animation-delay: -4s; }
.slideshow #slide-1 ,.slideshow #slideSp-1 ,.slideshow #radiolabel1 ,.slideshow #radiolabel1Sp { animation-delay: -8s; }
*/

/* :::::: arrow mechanism :::::: */
.arw label {
    pointer-events: none;
}
.selectFlightType1 {
    font-size: 24px;
    font-weight: bold;
    color:white;
    text-shadow: 0 0 7px black;
}
.selectFlightType2 {
    display:block;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    color:white;
    text-shadow: 0 0 7px black;
}


@media (max-width: 600px) {
    .landing_sp {
        display: block;
        /* position:absolute; */        /* TODO:意図が不明 */
        width:100%;
    }

    .landing {
        display: none;
    }

    .slideshowOuter {
        top: 0px;
        height:100%;
    }

    .landingCtl{
        position:absolute;
        top:0;
        margin-top:0px;
        margin-left:auto;
        margin-right:auto;
        z-index:3;
        width:100%;
        height:100%;
    }

    .landingMenu {
        display: block;
        text-align: center;
        vertical-align: top;
        margin:10px auto auto auto;
        padding: 0;
        z-index: 300;
    }

    .landingMenu1 {
        margin: 12px auto 12px auto;
    }
    .landingMenuBox{
        width:300px;
    }

}
