.dialogBackground {
	height:100%;
	width:100%;
	background:rgba(0,0,0,.5);
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dialogMain {
	height:auto;
	max-width:950px;
	width:80%;
	background-color:#fff;
    padding: 30px;
	/* margin:auto; */
	text-align: center;
}

.dialogTitle {

}

.dialogLine {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
	border: 0;
	border-top: 1px solid #333;
	margin: 20px 0;
}

.dialogBody {
	padding:10px 0;
	font-size:20px;
	margin-bottom:12px;
}

.dialogMinibody {
	font-size : 12px;
	color : #999;
}


/* 旧 */

#modal-root{
	position: absolute;
	z-index: 9999;
	top:0;
	left:0;
}
.modal-base{
	position:fixed;
	height:100%;
	width:100%;
	background:rgba(0,0,0,.5);
	top:0;
	left:0;
}

.dialogBase {
	position:fixed;
	height:100%;
	width:100%;
	background:rgba(0,0,0,.5);
	top:0;
	left:0;
	z-index:3
}

.dialogCover {
    position:absolute;
	/* background-color:#fff; */
	/*max-height:280px;*/
	height:auto;
	max-width:900px;
	width:80%;
    /* padding: 30px; */

    /*top:0;*/
    /*right:0;*/
    /*bottom:0;*/
    /*left:0;*/
    /*margin:auto;*/
}

.dialogInCover {
	/*width: 600px;*/
	background-color:#fff;
    padding: 30px;
	margin:auto;
	text-align:center;
}

.dialogHr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
	border: 0;
	border-top: 1px solid #333;
	margin: 20px 0;
}

.dialogText {
	padding:10px 0;
	font-size:20px;
	margin-bottom:12px;
}

.dialogText {
	padding:10px 0;
	font-size:20px;
	margin-bottom:12px;
}

.dialotTextLine {
    margin-top:0px !important;
    margin-bottom:0px !important;
    /*text-align:left;*/
}


/**
*	クレジットカード
*/
.bigRadio{
	width:100%;
}

/** max-heightでtransitionを行うと速度が違うので調整 */
.closeInput{
	max-height: 0;
	padding: 0 !important;
	-webkit-transition: max-height 0.8s cubic-bezier(0, 1, 0, 1); /* Safari */
	transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
	overflow: hidden;
}

.openInput{
	max-height: 100vh !important;
	-webkit-transition: max-height 1.0s cubic-bezier(0.4, 0, 1, 1) !important; /* Safari */
	transition: max-height 1.0s cubic-bezier(0.4, 0, 1, 1) !important;
}

.inputLabelSpace{
	display: inline-block;
}

.cardErrorMessage{
	margin-left: 0px !important;
}

@media (min-width: 650px){
	.cardErrorMessage{
		margin-left: 120px !important;
	}
}

@media (max-width:590px){
	.inputLabelSpace{
		display: none;
	}
}