/*
 * Login.js
 * RegisterNewMember.js
 */

.loginXcontainer > div {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2)
}

.facebook,.google,.twitter {
    color: #fff;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    height: 40px;
    font-weight: 600;
}
.twitter{
    background-color: #117acf;
}
.facebook {
    background-color: #4267b2;
}
.google {
    background-color: #db4437;
}

.loginButtonFull{
    line-height: 41px !important;
}

.userLinkContent {
    width: 290px;
    margin: auto;
    margin-bottom: 10px;
    text-align: center;
}
.userLink {
    width: 193px;
    margin: auto;
}

.userLinkContent a,.userLink a {

    color: #6e95cc;
    line-height: 40px;
    height: auto;
    text-align: center;

}

.loginSeparateBox {
    max-width: 300px;
    width: 300px;
    max-height: 1cm;
    min-height: 1cm;
    position: relative;
    margin: 26px auto 20px;
    text-align:center;
}
.loginSeparateBorder{
  padding-top: 6px;
  border-bottom: 1.5px solid #000;
}
.loginSeparateText {
    font-weight: 800;
    background-color: #fff;
    width: 100px;
    color: #000;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    bottom: 31px;
    transform: translate(-50%,50%);
}

/* ログイン画面 ログイン パスワードバリデート表現 */
.inputLoginErrorField {
    width: 298px;
    margin: auto;
    text-align: left;
    color: red
}

/* ログイン画面 ログイン パスワード */
.inputLogin {
    width: 300px;
}

.inputFieldCover,.inputFieldFirstCover {
    width: 300px;
    margin: 0 auto
}

.toEdit{
    line-height: 41px;
}

.backToTop,.toEdit {
    display: block
}

.backToTopHidden,.toEdit {
    background: #fff;
    width: 200px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin: 5px auto;
    color: #000;
    border: 1px solid #000;
    cursor: pointer
}
.backToTop {
    background: #fff;
    width: 200px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #000;
    border: 1px solid #000;
    cursor: pointer
}

.backToTop {
    background: #f5f5f5;
    margin-top: 80px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.backToTopHidden {
    display: none
}

.flexContainer {
    width: 1000px;
    margin: auto
}

.ringLogo {
    width: 320px;
    height: auto;
    float: left;
    margin-top: 80px;
    margin-left: 135px;
}

.ringLogo img, .ringLogoTop img {
    width: 200px;
}
.ringLogoTop {
    width: 200px;
    height: auto;
    margin: auto auto 10px;
    display: none
}

.loginIconCover {
    width: 300px;
    margin: 0 auto 10px;
}

.loginSubText {
    font-size: 12px;
}

.loginSubTextBold{
  font-weight: bold;
}

.loginXcontainer {
    padding-top: 8%;
    padding-left: 47%;
}
.loginXcontainer > div {
    background-color: #fff;
    max-width: 450px;
    min-height: 350px !important;
    margin: auto;
    padding: 50px 0 20px
}

@media (max-width:1000px) {
    .ringLogoTop {
        padding-top: 30px
    }

    .backToTopHidden,.ringLogoTop {
        display: block
    }

    .ringLogo {
        display: none
    }

    .loginXcontainer {
        margin: auto;
        padding: 0;
    }

    .flexContainer {
        width: auto;
        margin: auto
    }
}

@media (min-width:601px) {
    .loginXcontainer{
        width: 100%;
    }
}

@media (min-width:375px) {
    .loginXcontainer{
        width: 95%;
    }
}

@media (min-width:320px) {
    .loginXcontainer{
        width: 95%;
    }
}

@media (max-width:320px) {
    .loginXcontainer > div {
        background-color: #fff;
        max-width: 320px;
        min-height: 600px !important;
        margin: auto 8px;
        margin-bottom:50px;
        padding: 20px 0
    }

    .twitter {
        background-color: #117acf;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        line-height: 40px;
        height: 40px
    }

    .loginIconCover {
        width: 95%;
        margin: 0 auto 10px;
        cursor: pointer;
    }

    .loginSeparateBox{
        width: 95%;
    }

    .inputLogin{
        width: 290px;
        margin: auto 5px;
    }
}
