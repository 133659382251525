/* 共通スタイル */
html, body {
  font-family: "游ゴシック体", 'Yu Gothic', YuGothic, "游ゴシック Medium", "Yu Gothic Medium", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  height: 100%;
  -ms-overflow-x: hidden;   /* IEで右に余白で出るため追加(TODO:原因) */
}

body {
  background-color: #f5f5f5;
}

#root, #root > div, #topdiv {
  height: 100%;
}

div * {
    -webkit-overflow-scrolling: touch;
}

a {
  color: inherit;
}

table {
  border-collapse: collapse;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.noSelect{
-khtml-user-select: none !important;
-webkit-touch-callout: none !important;
user-select: none !important;
}

.tapHighLightIssue{
/* -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); */
-webkit-tap-highlight-color: rgba(0,0,0,0);
-webkit-tap-highlight-color: transparent;
}

.GrayText {
	color: #B3B6BA;
	font-size: 11px;
	position: relative;
	margin-top: 15px;
}

hr.divider {
  border: 0;
  border-top: 1px solid #eee;
  margin: 20px 0;
}


/**
 * RegistrationBase.css
*/

/* 各エラー表現 */
.customError {
	display:none;
	margin-left:5px;
	color:red;
	font-size:12px
}

@media (min-width:650px) {
    .customError {
      margin-left:25%;
    }
}
