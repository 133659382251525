.loading {
    position            : fixed;
    top                 : 0px;
    left                : 0px;
    background-color    : #aaaaaa;
    opacity             : 0.5;
    width               : 10000px;
    height              : 10000px;
    z-index             : 100;
    text-align          : center;
}
.loading_img_cover {
    top:0;
    right:0;
    bottom:0;
    left:0;
    width:100%;
    height:100%;
    margin              : auto;
}
.loading_img {
    width               : 100px;
    height              : 100px;
    margin              : auto;
}
